import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as URL from '../constants/url';
import { CommonService } from '../service/common.service';
import { APIClientService } from '../service/APIClientService';
import { HttpClient } from '@angular/common/http';
import *as ROUTS from '../constants/routs';
import { Http, RequestOptions, Headers, URLSearchParams } from '@angular/http';
import { map } from 'rxjs/operators';
import { CONSTANTS } from '../constants/constant';
@Injectable()
export class LoginService {


	constructor(private httpClient: HttpClient, private http: Http, private commonService: CommonService, private apiClientService: APIClientService) { }


	login(email: string, password: string): Observable<any> {

		let urlSearchParams = new URLSearchParams('');

		urlSearchParams.append('username', email);
		urlSearchParams.append('password', password);
		urlSearchParams.append('grant_type', "password");

		let body = urlSearchParams.toString();

		return this.http.post(URL.LOGIN, body, this.getCommonRequestOptionsForURLEncoded(URL.AUTHORIZATION)).pipe(
			map(response => response.json())
		);
	}


	getCommonRequestOptionsForURLEncoded(authorization: string): RequestOptions {

		let headers = new Headers();
		headers.append('Authorization', (authorization == URL.AUTHORIZATION ? authorization : authorization + this.getToken()));
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		headers.append('Accept', 'application/json');
		return new RequestOptions({ headers: headers });
	}

	getToken(): string {
		return this.commonService.decrypt(this.commonService.getCookie("accessToken"));
	}

	/**
	 * logOut
	 */
	logOut(): Observable<any> {

		return this.apiClientService.doGet(URL.LOGOUT);
	}

	/**
	 * Signup new customer
	 */
	signupCustomer(customerDto: any): Observable<any> {

		return this.apiClientService.doPostWithOutAuth(URL.SIGNUP, customerDto);

	}


	/**
	 * verify customer
	 */
	verifyCustomer(date, loginCredentialId): Observable<any> {

		return this.apiClientService.doGetWithOutAuth(URL.VERIFY_CUSTOMER_EMAIL + '/' + date + '/' + loginCredentialId);
	}

	/**@abstract
	 * Change password 
	 *
	 */
	changePassword(oldPassword, newPassword): Observable<any> {

		let urlSearchParams = new URLSearchParams('');

		urlSearchParams.append('oldPassword', oldPassword);
		urlSearchParams.append('newPassword', newPassword);

		return this.apiClientService.doPostWithHttp(URL.CHANGE_PASSWORD, urlSearchParams);
	}
	
	forgotPasswordEmail(emailId:string):Observable<any>{
		let urlSearchParams = new URLSearchParams('');

		urlSearchParams.append('email', emailId);

		return this.apiClientService.doPostWithOutAuth(URL.FORGOT_PASSWORD_EMAIL, urlSearchParams);
	}
	
	/**
	 * set password
	 */
	setPassword(loginCredentialId ,password) : Observable<any> {
		
		let  urlSearchParams = new URLSearchParams('');
	
		urlSearchParams.set('systemCredentialId', loginCredentialId);
		urlSearchParams.set('password', password);
	 
		return this.apiClientService.doPostWithOutAuth(URL.SET_PASSWORD ,urlSearchParams);
	 }
	 
	 /**
	  * Get profile details
	  */
	 getProfileDetails():Observable<any>{
		 return this.apiClientService.doGet(URL.GET_PROFILE_DETAILS);
	 }
	 
	 /**
	 * Update format
	 */
	updateDateFormat(dateFormat): Observable<any> {
		
		return this.apiClientService.doGet(URL.UPDATE_DATE_FORMAT+"/"+dateFormat);
	}
	
	/**
	 * Get date format LIst
	 */
	getDateFormatList() : Observable<any> {
		
		return this.apiClientService.doGet(URL.GET_DATE_FORMAT);
	}
	
	
	updateProfile(profileForm) : Observable<any>{
		return this.apiClientService.doPost(URL.UPDATE_PROFILE,profileForm);
		
	}

}
