import { Component,OnInit } from '@angular/core';
import { CONSTANTS } from '../app/constants/constant';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {


  constructor() { }

  ngOnInit(): void {
  
  }


}
