import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import * as ROUTS from '../../constants/routs';
import { CommonService } from '../../service/common.service';
import { Cookie } from 'ng2-cookies/ng2-cookies';
import { CONSTANTS } from '../../constants/constant';


@Injectable({ providedIn: 'root' })
export class AccessGuard implements CanActivate {

	constructor(private router: Router, private commonService: CommonService) { }

	pemissionList: string[] = [];

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

		// for Reference
		// https://stackoverflow.com/questions/41922466/redirect-user-with-router-depending-on-logged-in-status
		// https://jasonwatmore.com/post/2018/05/23/angular-6-jwt-authentication-example-tutorial

		//check already login then redirect on As per role
		this.checkLoginAndRedirect()
		// this.router.navigateByUrl( '/' + ROUTS.SIGN_IN );
		return true;
	}

	/**
	 * Method to check user login by cookie
	 */
	checkLogin(): boolean {

		if (this.checkIsNullOrUndefined(Cookie.get(CONSTANTS.COOKIE_ACCESSTOKEN)))
			return false;

		return true;
	}


	checkLoginAndRedirect() {

		if (this.checkLogin()) {

			// this.router.navigate(['/dashBoard'], { queryParams: { returnUrl: state.url }});

			// Redirect as per role
			this.redirectUserByUserRole(this.commonService.getCookie(CONSTANTS.COOKIE_USER_ROLE));
		} /*else {
			this.router.navigateByUrl( '/' + ROUTS.SIGN_IN );
		}*/
	}

	// Redirect as per role
	async redirectUserByUserRole(userRole: string) {


		if (userRole == "ROLE_SUPER_ADMIN") {
			this.router.navigateByUrl('/' + ROUTS.DASHBOARD);//this.navigateByPermission();
		} else if (userRole == "ROLE_CUSTOMER_ADMIN") {


			this.router.navigateByUrl('/' + ROUTS.DASHBOARD);

		} else {

			this.router.navigateByUrl('/' + ROUTS.SIGN_IN);
		}

	}

	/**
	 * Check is null
	 */
	checkIsNullOrUndefined(data: any) {
		if (data == null || data == undefined || data == '')
			return true;
		return false;
	}


}
