import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as URL from '../constants/url';
import { CommonService } from '../service/common.service';
import { HttpClient } from '@angular/common/http';
import { Http, RequestOptions, Headers, URLSearchParams } from '@angular/http';
import { APIClientService } from '../service/APIClientService';
import { GrandCalculation } from '../constants/models/grand-calculation';
import { UrlEncoder } from '../service/urlEncoderService';
@Injectable()
export class CustomerService {


	constructor(public apiClientService: APIClientService) { }

	/**
	 * Update format
	 */
	updateDateFormat(dateFormat): Observable<any> {
		
		let urlSearchParams = new URLSearchParams('');
		urlSearchParams.set('dateFormat',dateFormat);
		return this.apiClientService.doPostWithHttp(URL.UPDATE_DATE_FORMAT, urlSearchParams);
	}

}
