import{Injectable}from'@angular/core';
import{HttpClient, HttpHeaders}from'@angular/common/http';
import{RouterModule,Router}from'@angular/router';
import{Http,Response}from'@angular/http';
import{Headers,RequestOptions,URLSearchParams}from'@angular/http';
import{Observable,Subject}from'rxjs';
import{map,take}from'rxjs/operators';
import{catchError}from'rxjs/operators';
import{Cookie}from'ng2-cookies';
import{CONSTANTS}from'../constants/constant';
import{CommonService}from'../service/common.service';
import*as URL from'../constants/url';
import*as ROUTS from'../constants/routs';
import{UrlEncoder}from '../service/urlEncoderService';
@Injectable()
export class APIClientService {

	constructor( private httpClient: HttpClient, private http: Http, public router: Router,
        public commonService: CommonService ) { }

	/**
	 * Get
	 */
    doGet(url: string ): Observable<any> {
    	return this.httpClient.get(url);
    }

	/**
	 * Post
	 */
    doPost(url: string, request: any ): Observable<any> {
    	return this.httpClient.post(url, request);
    }
    
    doPostWithHeaders(url: string, request: any ): Observable<any> {
		const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    	return this.httpClient.post(url, request, { headers });
    }
    
        


	/**
	 * Delete
	 */
    doDelete(url: string ): Observable<any> {
    	return this.httpClient.delete(url);
    }

	/**
	 * Handle error
	 */
    public handleError(e: any ): any {

        this.router.navigateByUrl( '/' + ROUTS.SIGN_IN );
    }
    
    /**
	 * Get without auth
	 */
    doGetWithOutAuth(url: string ): Observable<any> {

    	 let headers = new Headers();
         this.commonService.setheadersforFormatAndLanguage(headers);
    	
         return this.http.get(url, {
    	      headers: headers
        });
    }
    
    /**
	 * Post without auth
	 */
    doPostWithOutAuth(url: string, request: any ): Observable<any> {
    	
    	 let headers = new Headers();
         this.commonService.setheadersforFormatAndLanguage(headers);
         
    	return this.http.post(url, request, {
    		headers: headers
    	});
    }
    
    /**
     * Post with http and authentication
     */
    doPostWithHttp( url: string, request: any ): Observable<any> {
        return this.http.post( url, request, this.commonService.getCommonRequestOptionsWithoutContentType( URL.BEARER ) ).pipe(
            map( response => response.json() ) );
    }
    
    /**
     * Post with http and authentication
     */
    doPostWithHttpAndResponseTypeBlob( url: string, request: any ): Observable<any> {
    	return this.http.post(url, request, this.commonService.getCommonRequestOptionsWithoutContentTypeWithResponseTypeBlob( URL.BEARER )).pipe(map(res => {
    		return {
      	         data: res.blob()
      	       };
    	}));
    }
}