import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { CommonService }from '../../service/common.service';
import { CONSTANTS } from '../../constants/constant';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private commonService : CommonService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) : boolean{

    		const role = this.commonService.getCookie(CONSTANTS.COOKIE_USER_ROLE);
    		
            // check if route is restricted by role
            /*if (route.data != "" && role != "ROLE_CUSTOMER_ADMIN" ) {
            	
                // role not authorised so redirect to home page
                this.router.navigate(['/notAuthorized']);
                return false;
            }*/

            // authorised so return true
            return true;
    }
}