import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as URL from '../constants/url';
import { CommonService } from '../service/common.service';
import { HttpClient } from '@angular/common/http';
import { Http, RequestOptions, Headers, URLSearchParams } from '@angular/http';
import { APIClientService } from '../service/APIClientService';
import { GrandCalculation } from '../constants/models/grand-calculation';
import { UrlEncoder } from '../service/urlEncoderService';
@Injectable()
export class DashboardService {


	constructor(public apiClientService: APIClientService) { }

	/**
	 * Get Today dashboard data
	 */
	getToday(): Observable<any> {

		return this.apiClientService.doGet(URL.TODAY);

	}

	/**
	  * Get Today yesterday data
	  */
	getYesterday(): Observable<any> {

		return this.apiClientService.doGet(URL.YESTERDAY);

	}

	/**
	  * Get Today last week data
	  */
	getLastWeek(): Observable<any> {

		return this.apiClientService.doGet(URL.LASTWEEK);

	}

}
