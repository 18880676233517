import { Injectable } from '@angular/core';
import * as jsPDF from 'jspdf';

@Injectable({
  providedIn: 'root'
})
export class GstbillgenerateService {

  constructor() { }


generateGstBillPdf(bill:any) {
   const doc = new jsPDF.default();

  // Generate the content of the GST bill
  const gstBillContent = `
    <html>
<head>
  <title>GST Invoice</title>
  <style>
    body {
      font-family: Arial, sans-serif;
    }

    .invoice-container {
      width: 800px;
      margin: 0 auto;
      padding: 20px;
      background-color: #f5f5f5;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }

    .invoice-header {
      text-align: center;
      margin-bottom: 20px;
    }

    .invoice-header h2 {
      color: #333;
    }

    .invoice-info {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
    }

    .invoice-info-left {
      font-weight: bold;
    }

    .invoice-info-right {
      text-align: right;
    }

    .invoice-table {
      width: 100%;
      border-collapse: collapse;
      margin-bottom: 20px;
    }

    .invoice-table th,
    .invoice-table td {
      padding: 10px;
      border-bottom: 1px solid #ddd;
    }

    .invoice-table th {
      text-align: left;
      background-color: #f2f2f2;
    }

    .invoice-total {
      text-align: right;
    }

    .invoice-footer {
      text-align: center;
      margin-top: 20px;
    }

    .invoice-footer p {
      color: #666;
    }
  </style>
</head>
<body>
  <div class="invoice-container">
    <div class="invoice-header">
      <h2>GST Invoice</h2>
    </div>
    <div class="invoice-info">
      <div class="invoice-info-left">
        <p>Customer Name: John Doe</p>
        <p>Address: 123 Main St, City</p>
      </div>
      <div class="invoice-info-right">
        <p>Date: July 12, 2023</p>
        <p>Invoice No: INV-001</p>
      </div>
    </div>
    <table class="invoice-table">
      <thead>
        <tr>
          <th>Item</th>
          <th>Quantity</th>
          <th>Price</th>
          <th>Total</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Product 1</td>
          <td>2</td>
          <td>$50.00</td>
          <td>$100.00</td>
        </tr>
        <tr>
          <td>Product 2</td>
          <td>1</td>
          <td>$80.00</td>
          <td>$80.00</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="3" class="invoice-total">Total</td>
          <td>$180.00</td>
        </tr>
      </tfoot>
    </table>
    <div class="invoice-footer">
      <p>Thank you for your business!</p>
    </div>
  </div>
</body>
</html>

  `;

  // Set the font style and size
  doc.setFont('Helvetica', 'normal');
  doc.setFontSize(12);

  // Add the GST bill content to the PDF
  doc.text(gstBillContent, 10, 10);

  // Save the PDF
  doc.save('GST_Bill.pdf');
}
}
