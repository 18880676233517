import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor,HttpResponse } from '@angular/common/http';
import { RouterModule, Routes, Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, map, filter, tap } from 'rxjs/operators';

import * as ROUTS from '../../constants/routs';

import { CommonService } from '../../service/common.service';
//import { LayoutService } from '../service/layout.service';

import { LoginService } from '../../service/login.service';

import { CONSTANTS } from '../constant';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private router: Router, private commonService: CommonService, 
    		  public loginService : LoginService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    	return next.handle(request).pipe(catchError(err => {
        	
            if (err.status == 401) {
            	
            	this.commonService.removeData();
            	this.commonService.sessionExpiredMessage = "Your session is expired. Please login again.";
            	
            	// BELOW CODE TO MOVE AT 401 PAGE OR LOGIN PAGE AS PER REQUIREMENT
            	
            	//this.router.navigate([ROUTS.ERROR_401]);
            	this.router.navigate([ROUTS.SIGN_IN]);
            	
            	// BELOW CODE TO GET FRESH ACCESS TOKEN BY REFRESH TOKEN, WE HAVE DISABLE IT BECAUSE OF NOT GETTING PREVIOUS REQUEST ON THIS PAGE
                
//                this.loginRepository.refreshToken().subscribe(( results ) => {
//            	
//            		this.commonService.setAuthorizationDataInCookie(results, true);
//                	
//	            	this.commonService.redirectUrl();
//	            	
//                }, ( error ) => {
//                	
//                	// when any user is deleted 401 error throws and will try to get new access token and in this case error will thrown then redirect to 401 after removing cookies
//                	
//                		this.commonService.removeData();
//                		
//                		this.commonService.sessionExpiredMessage = "Your session is expired. Please login again.";
//                		this.router.navigate([ROUTS.SIGN_IN]);
//                	
//                }) ;
            		
            }
            
            if (err.status == 403) {
                this.router.navigate([ROUTS.NOT_AUTHORIZED]);
            }
            
            if (err.status == 404) {
                this.router.navigate([ROUTS.ERROR]);
            }
            
            if (err.status == 0) {
            	this.router.navigate([ROUTS.MAINTENANCE]);
            	
            }
            
           /* if (err.status == '(failed)net::ERR_CERT_DATE_INVALID') {
            	this.router.navigate([ROUTS.SSL_MAINTENANCE]);
            	
            }*/
            
            return throwError(err.error);
            
        }));
    }
}