import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as URL from '../constants/url';
import { URLSearchParams } from '@angular/http';
import { APIClientService } from '../service/APIClientService';
import { UrlEncoder } from '../service/urlEncoderService';
@Injectable()
export class ReportService {


	constructor(public apiClientService: APIClientService) { }


	/**
	 * Get product wise price date range
	 */
	getProductWisePriceForDateRange(fromDate: any, toDate: any): Observable<any> {

		let urlSearchParams = new URLSearchParams('', new UrlEncoder());

		urlSearchParams.append('fromDate', fromDate);
		urlSearchParams.append('toDate', toDate);

		return this.apiClientService.doPostWithHttp(URL.PRODUCT_WISE_SELLING, urlSearchParams);

	}

	loadDataForBillTypeTotalSellingChart(billType: string, fromDate: any, toDate: any): Observable<any> {

		let urlSearchParams = new URLSearchParams('', new UrlEncoder());

		urlSearchParams.append('sellingType', billType);
		urlSearchParams.append('fromDate', fromDate);
		urlSearchParams.append('toDate', toDate);

		return this.apiClientService.doPostWithHttp(URL.DATE_WISE_SELLING, urlSearchParams);
	}

}
