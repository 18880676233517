import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as URL from '../constants/url';
import { CommonService } from '../service/common.service';
import { HttpClient } from '@angular/common/http';
import *as ROUTS from '../constants/routs';
import { Http, RequestOptions, Headers, URLSearchParams } from '@angular/http';
import { map } from 'rxjs/operators';
import { CONSTANTS } from '../constants/constant';
import { APIClientService } from '../service/APIClientService';
@Injectable()
export class ProductService {


	constructor(private httpClient: HttpClient, private http: Http, private commonService: CommonService, public apiClientService: APIClientService) { }


	/**
	 * Get all product list
	 */
	getAllProduct(start: any, length: any): Observable<any> {

		let urlSearchParams = new URLSearchParams('');

		urlSearchParams.append('start', start);
		urlSearchParams.append('length', length);

		return this.apiClientService.doPost(URL.GET_ALL_PRODUCTS, urlSearchParams);
	}


	/**
	 * Get all product list
	 */
	getProductById(productId: any): Observable<any> {


		return this.apiClientService.doGet(URL.GET_PRODUCT_BY_ID + productId);
	}
	
	/**
	 * Save product
	 */
	saveProduct(productDto: any): Observable<any> {

		return this.apiClientService.doPost(URL.SAVE_PRODUCT ,productDto);
	}


}
