import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from '../app/modules/dashboard/dashboard.component';
import { FormsModule, ReactiveFormsModule, FormGroup } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe } from '@angular/common';
import * as ROUTS from '../app/constants/routs'
import { CommonService } from './service/common.service';
import { LoginService } from './service/login.service';
import { APIClientService } from './service/APIClientService';
import { GstbillgenerateService } from './service/gstbillgenerate.service';
import { PagerService } from './service/pagerService';
import { ProductService } from './service/product.service';
import { BillService } from './service/bill.service';
import { SubscriptionService } from './service/subscription.service';
import {CustomerService} from  './service/customer.service';
import { DashboardService } from './service/dashboard.service';
import { ReportService } from './service/report.service';
import { BasicAuthInterceptor } from '../app/constants/interceptor/basic-auth.interceptor';
import { ErrorInterceptor } from '../app/constants/interceptor/error.interceptor';
import { AppComponent } from './app.component';
import { NgSelectModule } from '@ng-select/ng-select';
import {AccessGuard} from './constants/guard/access.guard';
import {AuthGuard} from './constants/guard/auth.guard';

import { NgChartsModule} from 'ng2-charts';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';

@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		NgChartsModule,
		BrowserModule,
		FormsModule,
		AutocompleteLibModule,
		BrowserAnimationsModule,
		ReactiveFormsModule,
		HttpClientModule,
		HttpModule,
		
		NgSelectModule,
		RouterModule.forRoot(ROUTS.routes)
	],
	providers: [AuthGuard, AccessGuard,{ provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
		{ provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
		CommonService, BillService, LoginService, APIClientService, GstbillgenerateService, 
		PagerService, ProductService, SubscriptionService,DashboardService,ReportService,CustomerService
	],

	bootstrap: [AppComponent]
})
export class AppModule { }
