export class CONSTANTS {
    
    
    public static COOKIE_ACCESSTOKEN = "accessToken";
	public static COOKIE_USER_ROLE = "role";
	public static COOKIE_USER_NAME = "userName";
	public static COOKIE_COMPANY_NAME ="companyName";
    public static COOKIE_REFRESHTOKEN = "refreshToken";
    public static REMEMBER_ME_FLAG : string = "rememberMeFlag";
    public static COMPANY_LOGO : string = "companyLogo";
    public static DEVICE_QR_CODE : string = "deviceQRCode";
    public static WORKING_DAYS  = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    public static DATE_FORMAT = "dateFormat";
    public static TIME_FORMAT = "timeFormat";
    public static DATE_TIME_FORMAT = "dateTimeFormat";
    public static DURATION_FORMAT = "durationFormat";
    public static DATE_FORMAT_DEFAULT = "dd MMM yyyy";
    public static TIME_FORMAT_DEFAULT = "hh:mm a";
    public static DATE_TIME_FORMAT_DEFAULT = "dd MMM yyyy, hh:mm a";
    public static DURATION_FORMAT_DEFAULT = "00h 00m 00s";
    public static IS_ANY_DEVICE_CONNECTED = "isAnyDeviceConnected";
    public static TRUE = "true";
    public static EMAIL = "email";
    public static CUSTOMER_TYPE = "customerType";
    public static SUBSCRIPTION_TYPE = "subscriptionType";
	public static SUBSCRIPTION_PLAN = "subscriptionPlan";
    public static PREVIOUS_LINK = "previousLink";
    public static PREVIOUS_LINK_EMAIL_NOTIFICATION_TAB = "/userSetting/emailNotification/9P8GiIh_xgS5dfbpvnkh9w";
    public static LEFT_MENU_OPEN_FLAG = "leftMenuOpenFlag";
    public static SUPPORT_TITLE = "supportTitle";
    public static REPOER_IS_ACTIVE = "reportIsActive";
    public static TIME_FORMAT_HH_MM = "HH:mm";
    public static IS_REDIRECTED_TO_VIEWLEAD = "isRedirectedToViewLead";
    public static IS_REDIRECTED_TO_PERIODIC_REPORTS  = "isRedirectedToPeriodicReports";
    public static DASHBOARD_TO_PERIODIC_REPORTS_REDIRECTED  = "dashBoardToPeriodicReportsRedirected";
    public static LEAD_CREATED_DATE_FORMAT  = "leadCreatedDateFormat";
    public static CLIENT_NUMBER = "clientNumber";
    public static LOGIN_CREDENTIAL_ID = "loginCredentialId";
    public static CUSTOMER_ID = "customerId";
    public static IS_SET_PASSWORD_FIRST_TIME = "isSetPassWordAfterVerified"
    public static PREVIOUS_LINK_SUBSCRIPTION_TAB = "/manageCustomer/subscription/";
    public static PREVIOUS_SUBSCRIPTION_LINK = "previousSubscriptionLink";
    public static DUPLICATE_ACC_TO_CUSTOMER_DETAILS_REDIRECT = "duplicateAccToCustomerDetailsRedirect";
    public static IS_MAINTENANCE_SHOW = "isMaintenanceShow";
    public static COOKIE_MAINTENANCE_TIME = "cookieMaintenanceTime";
	public static EMPLOYEE_REPORT_TO_PERIODIC_REPORT_REDIRECTED = "employeeReportToPeriodicReportRedirect";
    public static RELOAD_LEAD_MODULE = "haveLeadFetureRedirectToMyLead";
    public static PAGE_SIZE_PREFERENCE_WISE = "pageSizePreferenceWise";
	
    public static MONDAY  = "Monday";
    public static TUESDAY  = "Tuesday";
    public static WEDNESDAY  = "Wednesday";
    public static THURSDAY  = "Thursday";
    public static FRIDAY  = "Friday";
    public static SATURDAY  = "Saturday";
    public static SUNDAY  = "Sunday";
    
    public static PDF  = "Pdf";
    public static EXCEL  = "Excel";
    
    public static EMAIL_PATTERN  = /^[_0-9A-Za-z][_0-9A-Za-z-.]+@([0-9A-Za-z][0-9A-Za-z-]+\.)+[A-Za-z]+$/;
    public static PHONE_PATTERN  = /^\s*(?:\+?(\d{1,3}))?[- (]*(\d{3})[- )]*(\d{3})[- ]*(\d{4})(?: *[x/#]{1}(\d+))?\s*$/;
    public static NUMBER_ONLY  = /^[0-9]*$/;
    
    
    public static LOGIN_COOKIE_EXPIRATION_DAYS : number = 60 * 60 * 24 * 365 ; // 1 year
    public static ENCRYPT_DECRYPT_SECRET_KEY : string = "1234567890";
    public static PATH_OF_COOKIE : string = "pathOfCookie";
    public static CRYPTO_SECRET_KEY = 'cryptoSecretKey';
    public static PERMISSIONS = 'permissions';
    public static USER_ROLE = 'role';
    public static PAGE_NUMBER_LIMIT_OPTIONS = [10, 15, 20];
    public static BRAND : string = 'brand';
    public static BRAND_ID : string = 'brandId';
    public static SUBMIT : string = 'Submit';
    public static SUBMITTING : string = 'Submitting...';
    public static LOGO_MIN_WIDTH : number = 100;
    public static LOGO_MIN_HEIGHT : number = 100;
    public static MAX_FILE_SIZE : number = 10000000;
    public static DATE_RANGE_DURATION : number = 120;
    public static SIX_MONTHS_DURATION : number = 180;
    
    //Uploading constants
    public static blockUIMessage : string = 'Please wait while uploading data, it may take several minutes.'
    public static invalidCsvMessage : string = 'Invalid csv file'
    
    //TIMEZONE RELATED CONSTANTS
    public static TIME_ZONE : string = 'timeZone'
    public static HEADER_TIME_ZONE : string = 'Time-Zone'
    
    //FILE TYPE
    public static CSV_FILE_TYPE : string = 'text/csv'
    public static CSV_TYPE : string = 'CSV'
    public static PDF_TYPE : string = 'PDF'
    
    public static CALLYZER_BIZ_PLAYSTORE_LINK : string = 'https://play.google.com/store/apps/details?id=com.websoptimization.callyzerbiz'
    
    public static COOKIE_LANDING_PAGE_URL = "landing_page_url";
    public static COOKIE_SOURCE_REFERRAL_URL = "source_referral_url"; 
	public static LEADTAG_AND_DEVICETAG_PATTERN = /[;",^=?|~`!\{\}\[\]<>\\]/; 
	
	//ERROR MSG Constants
	public static ERROR_MESSAGE_FOR_LEADTAG_AND_DEVICETAG : string = 'Special characters like ; " , ^ = ? \\ | ~ ` ! { } [ ] < > are not allowed for tags'
	public static ERROR_MAX_120_DAYS: string = 'Date range should not be greater than 120 days';
	public static ERROR_MAX_180_DAYS: string = 'Date range should not be greater than 180 days'; 
	
	//country code array for which we allow leadding zero in phone number & secondaryPhone number
	public static COUNTY_CODE = [225];

	public static ERROR_MESSAGE_FOR_DEVICE_ADD_COMMENT : string = 'Comment should not be null' ;
	public static LEAD_FEATURE : string = 'Lead';
    public static CALL_RECORDING_FEATURE : string = 'CallRecording';
  
    public static SUBSCRIPTION_EXPIRED_ERROR_MESSAGE = 'Your subscription period is already expired';	
	
	
	public static IS_TWO_STEP_VERIFICATION = 'isTwoStepVerification';
	public static IS_TWO_STEP_VERIFICATION_DEFAULT = 'false'; 
	
	public static OTP_GENERATE_STATUS_CODE = '1';
	public static INVALID_OTP_STATUS_CODE = '2';

    public static DEFAULT_PAGE = 50;
    
    public static COMPONENTS_LIMIT = 20;
    
    public static DATE_FORMATES = ['dd/MM/YYYY', 'MM/dd/YYYY', 'YYYY/MM/dd'];

    public static Integromat =' Make (formerly Integromat)';
	
    public static CUSTOMER_SUBSCRIPTION ='customerSubscription';
    
    public static GstBill='GstBill';
    public static NonGstBill='NonGstBill';

}
