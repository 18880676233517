import { RouterModule, Routes } from '@angular/router';
import { AccessGuard } from '../constants/guard/access.guard';

//export const HOME = '';
export const SIGN_IN = 'signin';
export const LAYOUT = '';
export const ERROR = 'error';
export const NOT_AUTHORIZED = 'notAuthorized';
export const ERROR_401 = 'error401';
export const MAINTENANCE = 'maintenance';
export const DASHBOARD = 'dashboard';
export const MANAGE_PRODUCTS = 'products';
export const GENERATE_GSTBILL = 'gstbill';
export const EDIT_GSTBILL = 'gstbill/edit/:id';
export const GENERATE_ROUGHBILL = 'roughbill';
export const EDIT_ROUGHBILL = 'roughbill/edit/:id';
export const ADD_PRODUCT = 'products/addProduct';
export const EDIT_PRODUCT = 'products/editProduct/:id';
export const SELLING_REPORT = 'sellingReport';
export const PURCHASE_REPORT = 'purchaseReport';
export const BILL_GENERATE = 'billGenerate';
export const PURCHASE_GST_BILL_GENERATE = 'purchasegstbill';
export const EDIT_PURCHASE_GST_BILL_GENERATE = 'purchasegstbill/edit/:id';
export const PURCHASE_ROUGH_BILL_GENERATE = 'purchaseroughbill';
export const EDIT_PURCHASE_ROUGH_BILL_GENERATE = 'purchaseroughbill/edit/:id';
export const SETTINGS = 'settings';
//export const PAYMENT_SUCCESS_COMPONENT_ROUTES = 'paymentsuccess/:id';
export const BORROW_AMOUNT_REPORT = 'borrowAmountReport';
export const VERIFY_CUSTOMER = 'verifyCustomer/:date/:loginCredentialId';
export const CHANGE_PASSWORD='changePassword';
export const FORGOT_PASSWORD='forgotPassword';
export const SET_PASSWORD='setPassword/:date/:loginCredentialId';

/**
 * All routs
 */
export const routes: Routes = [
	{ path: "", loadChildren: () => import('../modules/signin/signin.module').then(module => module.SigninModule), canActivate: [AccessGuard] },
	{ path: SIGN_IN, loadChildren: () => import('../modules/signin/signin.module').then(module => module.SigninModule), canActivate: [AccessGuard] },
	{ path: LAYOUT, loadChildren: () => import('../modules/layout/layout.module').then(module => module.LayoutModule) },
	{ path: DASHBOARD, loadChildren: () => import('../modules/dashboard/dashboard.module').then(module => module.DashboardModule) },
	{ path: MANAGE_PRODUCTS, loadChildren: () => import('../modules/products/products.module').then(module => module.ProductsModule) },
	{ path: GENERATE_GSTBILL, loadChildren: () => import('../modules/gstbill/gstbill.module').then(module => module.GstbillModule) },
	{ path: EDIT_GSTBILL, loadChildren: () => import('../modules/gstbill/gstbill.module').then(module => module.GstbillModule) },
	{ path: GENERATE_ROUGHBILL, loadChildren: () => import('../modules/roughbill/roughbill.module').then(module => module.RoughbillModule) },
	{ path: EDIT_ROUGHBILL, loadChildren: () => import('../modules/roughbill/roughbill.module').then(module => module.RoughbillModule) },
	{ path: ADD_PRODUCT, loadChildren: () => import('../modules/addproduct/addproduct.module').then(module => module.AddproductModule) },
	{ path: EDIT_PRODUCT, loadChildren: () => import('../modules/addproduct/addproduct.module').then(module => module.AddproductModule) },
	{ path: SELLING_REPORT, loadChildren: () => import('../modules/sellingreport/sellingreport.module').then(module => module.SellingreportModule) },
	{ path: PURCHASE_REPORT, loadChildren: () => import('../modules/purchase-report/purchase-report.module').then(module => module.PurchaseReportModule) },
	{ path: BILL_GENERATE, loadChildren: () => import('../modules/billgenerate/billgenerate.module').then(module => module.BillgenerateModule) },
	{ path: EDIT_PURCHASE_GST_BILL_GENERATE, loadChildren: () => import('../modules/purchasegstbill/purchasegstbill.module').then(module => module.PurchasegstbillModule) },
	{ path: PURCHASE_GST_BILL_GENERATE, loadChildren: () => import('../modules/purchasegstbill/purchasegstbill.module').then(module => module.PurchasegstbillModule) },
	{ path: EDIT_PURCHASE_ROUGH_BILL_GENERATE, loadChildren: () => import('../modules/purchaseroughbill/purchaseroughbill.module').then(module => module.PurchaseroughbillModule) },
	{ path: PURCHASE_ROUGH_BILL_GENERATE, loadChildren: () => import('../modules/purchaseroughbill/purchaseroughbill.module').then(module => module.PurchaseroughbillModule) },
	{ path: SETTINGS, loadChildren: () => import('../modules/settings/settings.module').then(module => module.SettingsModule) },
	//{ path: PAYMENT_SUCCESS_COMPONENT_ROUTES, loadChildren: () => import('../modules/payment-success/payment-success.module').then(module => module.PaymentSuccessModule) },
	{ path: BORROW_AMOUNT_REPORT, loadChildren: () => import('../modules/borrow-amount-report/borrow-amount-report.module').then(module => module.BorrowAmountReportModule) },
	{ path: VERIFY_CUSTOMER, loadChildren: () => import('../modules/verify-customer/verify-customer.module').then(module => module.VerifyCustomerModule) },
	{ path: CHANGE_PASSWORD, loadChildren: () => import('../modules/changepassword/changepassword.module').then(module => module.ChangepasswordModule) },
	{ path: FORGOT_PASSWORD, loadChildren: () => import('../modules/forgotpassword/forgotpassword.module').then(module => module.ForgotpasswordModule) },
	{ path: SET_PASSWORD, loadChildren: () => import('../modules/setpassword/setpassword.module').then(module => module.SetpasswordModule) },
	{ path: ERROR, loadChildren: () => import('../modules/signin/signin.module').then(module => module.SigninModule), canActivate: [AccessGuard] },
	{ path: NOT_AUTHORIZED, loadChildren: () => import('../modules/signin/signin.module').then(module => module.SigninModule), canActivate: [AccessGuard] },
	{ path: ERROR_401, loadChildren: () => import('../modules/signin/signin.module').then(module => module.SigninModule), canActivate: [AccessGuard] },
	{ path: MAINTENANCE, loadChildren: () => import('../modules/signin/signin.module').then(module => module.SigninModule), canActivate: [AccessGuard] },
]

