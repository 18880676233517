import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as URL from '../constants/url';
import { CommonService } from '../service/common.service';
import { HttpClient } from '@angular/common/http';
import { Http, RequestOptions, Headers, URLSearchParams } from '@angular/http';
import { APIClientService } from '../service/APIClientService';
import { GrandCalculation } from '../constants/models/grand-calculation';
import { UrlEncoder } from '../service/urlEncoderService';
@Injectable()
export class BillService {


	constructor(public apiClientService: APIClientService) { }


	/**
	 * Generate & save bill info
	 */
	generateBill(billDto: GrandCalculation): Observable<any> {

		return this.apiClientService.doPost(URL.GENERATE_BILL, billDto);
	}

	/**
	 * Get bill history
	 */
	getBillHistory(fromDate: any, toDate: any, isPurchaseBill: any, start: any, length: any): Observable<any> {

		let urlSearchParams = new URLSearchParams('');

		urlSearchParams.append('fromDate', fromDate);
		urlSearchParams.append('toDate', toDate);
		urlSearchParams.append('isPurchaseBill', isPurchaseBill);
		urlSearchParams.append('start', start);
		urlSearchParams.append('length', length);

		return this.apiClientService.doPostWithHeaders(URL.GET_BILL_HISTORY, urlSearchParams.toString());

	}


	/**
	 * Get bill details by id
	 * 
	 */
	getBillDetailsById(id: string): Observable<any> {

		return this.apiClientService.doGet(URL.GET_BILL_DETAILS + id);
	}

	deleteBill(id: string): Observable<any> {

		return this.apiClientService.doDelete(URL.DELETE_BILL_BY_ID + id);
	}

	/**
	 * Get customer client details by name
	 * 
	 */
	getCustomerClients(name: string): Observable<any> {

		return this.apiClientService.doGet(URL.GET_CUSTOMER_CLIENT_BY_NAME + name);
	}

}
