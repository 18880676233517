import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CommonService } from '../../service/common.service';
import { CONSTANTS } from '../constant';

@Injectable()
export class BasicAuthInterceptor implements HttpInterceptor {
	constructor(private commonService: CommonService) { }

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

		try {
			// add authorization header with basic auth credentials if available
			let currentUser = this.commonService.decrypt(this.commonService.getCookie(CONSTANTS.COOKIE_ACCESSTOKEN));

			//let language =   this.commonService.getLanguage();

			let localTimeZone = this.commonService.getLocalTimeZone();

			let localDateFormat = this.commonService.getDateFormat();

			let localTimeFormat = this.commonService.getTimeFormat();

			let localDateTimeFormat = this.commonService.getDateTimeFormat();

			//let localDurationFormat =   this.commonService.getDurationFormat();

			if (currentUser != null) {
				//'Accept-Language' : `english`,
				request = request.clone({
					setHeaders: {
						Authorization: `Bearer ${currentUser}`,
						'Time-Zone': `${localTimeZone}`,
						'Date-Format': `${localDateFormat}`,
						'Time-Format': `${localTimeFormat}`
					}
				});

				return next.handle(request);

			} else {

				this.commonService.redirectToLogin();
			}
		} catch (error) {
			throw (error);
		}

		return next.handle(request);

	}
}