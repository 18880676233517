import { environment } from '../../environments/environment';

'use strict';

export const BASE_URL: string = environment.apiUrl;
export const LOGIN: string = BASE_URL + "oauth/token";
export const AUTHORIZATION: string = "Basic bVkjQnpORSQkX0MxIUVudDptWSNCek5FJCRfJEVjUkV0";
export const BEARER: string = "Bearer ";
export const LOGOUT: string = BASE_URL + "oauth/logout";


// PRODUCT
export const PRODUCT: string = BASE_URL + "admin/product/";
export const GET_ALL_PRODUCTS: string = PRODUCT + "getAll";
export const GET_PRODUCT_BY_ID: string = PRODUCT + "get/";
export const SAVE_PRODUCT: string = PRODUCT + "save";


// Bill
export const BILL: string = BASE_URL + "admin/bill/";
export const GENERATE_BILL: string = BILL + "generateBill";
export const GET_BILL_HISTORY: string = BILL + "getBillHistory";
export const GET_BILL_DETAILS: string = BILL + "get/";
export const DELETE_BILL_BY_ID: string = BILL + "delete/";


// REPORT
export const REPORT: string = BASE_URL + "reports/";
export const PRODUCT_WISE_SELLING: string = REPORT + "productWiseSelling";
export const DATE_WISE_SELLING: string = REPORT + "dateWiseSelling";


// CUSTOMER CLIENT
export const CUSTOMER_CLIENT: string = BASE_URL + "admin/customer/client/";
export const GET_CUSTOMER_CLIENT_BY_NAME: string = CUSTOMER_CLIENT + "getByName?clientName=";

// Subscription
//export const SUBSCRIPTION: string = BASE_URL + "admin/subscription/";
//export const CREATE_SUBSCRIPTION: string = SUBSCRIPTION + "createSubscription";
//export const GET_TRANSACTION_LOG_BY_TRANSACTION_ID: string = SUBSCRIPTION + "getTransactionLogById";
//export const GET_PLAN_LIST_FOR_CUSTOMER: string = SUBSCRIPTION + "getPlansForCustomer";

// NoAuth
export const NOAUTH: string = BASE_URL + "noAuth/";
export const SIGNUP: string = NOAUTH + "signUp";
export const VERIFY_CUSTOMER_EMAIL: string = NOAUTH + "verifyCustomerEmail";
export const FORGOT_PASSWORD_EMAIL: string = NOAUTH + "forgotPassword";
export const SET_PASSWORD: string = NOAUTH + "setPassword";
export const GET_DATE_FORMAT: string = NOAUTH + "getDateFormat";


// Dashboard
export const DASHBOARD: string = BASE_URL + "admin/dashboard/";
export const TODAY: string = DASHBOARD + "getToday";
export const YESTERDAY: string = DASHBOARD + "getYesterday";
export const LASTWEEK: string = DASHBOARD + "getLastWeek";


//Customer
export const CUSTOMER: string = BASE_URL + "admin/customer/";
export const CHANGE_PASSWORD: string = CUSTOMER + "updatePassword";
export const UPDATE_PROFILE: string = CUSTOMER + "updateProfile";
export const GET_PROFILE_DETAILS: string = CUSTOMER + "getProfileDetails"
export const UPDATE_DATE_FORMAT: string = CUSTOMER + "updateDateFormat"