import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as URL from '../constants/url';
import { APIClientService } from '../service/APIClientService';
import { Http, RequestOptions, Headers, URLSearchParams } from '@angular/http';
import { GrandCalculation } from '../constants/models/grand-calculation';
import { UrlEncoder } from '../service/urlEncoderService';
@Injectable()
export class SubscriptionService {


	constructor(public apiClientService: APIClientService) { }


	/**
	 * Generate & save bill info
	 */
	createSubscription(planType, planDuration): Observable<any> {
		
		const body = {
      planType: planType,
      planDuration: planDuration
    };


		return this.apiClientService.doPost("",null);//(URL.CREATE_SUBSCRIPTION, body);
	}


	/**
	 * Get transaction by id
	 */
	getTransactionLogByTransactionId(transactionId: any): Observable<any> {

		let urlSearchParams = new URLSearchParams('', new UrlEncoder());

		urlSearchParams.set('transactionId', transactionId);

		return this.apiClientService.doPost("",null);//(URL.GET_TRANSACTION_LOG_BY_TRANSACTION_ID, urlSearchParams);
	}

	/**@abstract
	 * Get plan list for customer in pageable format
	 */
	getAllPlans(start, length): Observable<any> {

		let urlSearchParams = new URLSearchParams('', new UrlEncoder());

		urlSearchParams.set('start', start);
		urlSearchParams.set('length', length);
		return this.apiClientService.doPost("",null);//(URL.GET_PLAN_LIST_FOR_CUSTOMER, urlSearchParams);


	}

}
