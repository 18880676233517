import { Injectable } from '@angular/core';
import { Cookie } from 'ng2-cookies';
import { Observable } from 'rxjs';
import * as CryptoJS from 'crypto-js';
import * as URL from '../constants/url';
import * as moment from 'moment-timezone';
import { Http, Response, ResponseContentType } from '@angular/http';
import { Headers, RequestOptions, URLSearchParams } from '@angular/http';
import { CONSTANTS } from '../constants/constant';
import *as ROUTS from '../constants/routs';
import { RouterModule, Routes, Router } from '@angular/router';
@Injectable({
	providedIn: 'root'
})
export class CommonService {

	ones = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
	teens = ['', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
	tens = ['', 'Ten', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];

	public setTitle: string = "";

	public sessionExpiredMessage: string = "";

	constructor(private router: Router) { }


	/**
		* Encrypt
		* 
		* @param {string}
		*            normalData [description]
		* @return {string} [description]
		*/
	encrypt(normalData: string): string {

		if (!normalData)
			return "";

		return CryptoJS.AES.encrypt(normalData, CONSTANTS.ENCRYPT_DECRYPT_SECRET_KEY).toString();
	}

	/**
	 * Decrypt
	 * 
	 * @param {string}
	 *            encryptedData [description]
	 * @return {string} [description]
	 */
	decrypt(encryptedData: string): string {

		if (!encryptedData)
			return "";

		var decrypted = CryptoJS.AES.decrypt(encryptedData, CONSTANTS.ENCRYPT_DECRYPT_SECRET_KEY);
		return decrypted ? decrypted.toString(CryptoJS.enc.Utf8).toString() : "";
	}

	setCookie(key: string, value: any, cookieExpirationDays?: number) {

		let path: string = "/";

		//If cookie already exists then remove it
		if (this.getCookie(key)) {
			this.removeCookie(key);
		}

		if (cookieExpirationDays) {
			Cookie.set(key, value, (cookieExpirationDays / 60 / 60 / 24), path);
			//Cookie.set(key, value, cookieExpirationDays);
		}
		else {
			Cookie.set(key, value, undefined, path);
		}
	}

	getCookie(key: string): any {

		return Cookie.get(key);
	}

	removeCookie(key: string) {

		let path: string = "/";

		Cookie.delete(key, path);
	}


	setAuthorizationDataInCookie(results: any) {

		let encryptedToken: string = this.encrypt(results.access_token);
		let refreshToken: string = this.encrypt(results.refresh_token);

		this.setCookie(CONSTANTS.COOKIE_ACCESSTOKEN, encryptedToken, CONSTANTS.LOGIN_COOKIE_EXPIRATION_DAYS);
		this.setCookie(CONSTANTS.COOKIE_REFRESHTOKEN, refreshToken, CONSTANTS.LOGIN_COOKIE_EXPIRATION_DAYS);


		this.setCookie(CONSTANTS.COOKIE_USER_ROLE, results.userRole[0], CONSTANTS.LOGIN_COOKIE_EXPIRATION_DAYS);


		localStorage.setItem(CONSTANTS.DATE_FORMAT, null != results.dateFormat ? results.dateFormat : CONSTANTS.DATE_FORMAT_DEFAULT);
		localStorage.setItem(CONSTANTS.TIME_FORMAT, null != results.timeFormat ? results.timeFormat : CONSTANTS.TIME_FORMAT_DEFAULT);
		//localStorage.setItem(CONSTANTS.DATE_TIME_FORMAT, null != results.dateTimeFormat ? results.dateTimeFormat : CONSTANTS.DATE_TIME_FORMAT_DEFAULT);
		//localStorage.setItem(CONSTANTS.DURATION_FORMAT, null != results.durationFormat ? results.durationFormat : CONSTANTS.DURATION_FORMAT_DEFAULT);
		localStorage.setItem(CONSTANTS.EMAIL, results.email);
		//localStorage.setItem(CONSTANTS.CUSTOMER_TYPE, results.customerType);
		//localStorage.setItem(CONSTANTS.SUBSCRIPTION_TYPE, results.subscriptionType);
		//localStorage.setItem(CONSTANTS.SUBSCRIPTION_PLAN, results.subscriptionPlan);
		//localStorage.setItem(CONSTANTS.LOGIN_CREDENTIAL_ID, results.loginCredentialId);
		localStorage.setItem(CONSTANTS.CUSTOMER_ID, results.customerId);// This id is customer's loginCredentialId not cutomerId

		// this is flag for allow/not allow download option for user of list of customer

		if (results.businessName !== null || results.businessName !== null) {

			//this.setCookie(CONSTANTS.COOKIE_USER_NAME, results.userName, CONSTANTS.LOGIN_COOKIE_EXPIRATION_DAYS);
			this.setCookie(CONSTANTS.COOKIE_COMPANY_NAME, results.businessName, CONSTANTS.LOGIN_COOKIE_EXPIRATION_DAYS);
		}


	}

	/**
		 * logout time remove cookie data 
		 */
	removeData() {

		this.removeCookie(CONSTANTS.COOKIE_ACCESSTOKEN);
		this.removeCookie(CONSTANTS.COOKIE_REFRESHTOKEN);
		this.removeCookie(CONSTANTS.COOKIE_USER_ROLE);
		this.removeCookie(CONSTANTS.COOKIE_USER_NAME);
		this.removeCookie(CONSTANTS.COOKIE_COMPANY_NAME);
		localStorage.removeItem(CONSTANTS.PREVIOUS_LINK);
		localStorage.removeItem(CONSTANTS.LOGIN_CREDENTIAL_ID);
		localStorage.removeItem(CONSTANTS.CUSTOMER_ID);
		localStorage.removeItem(CONSTANTS.PAGE_SIZE_PREFERENCE_WISE);
	}

	redirectErrorPage(errorStatus: any) {


		this.setTitle = "Oooopppss !";

		if (errorStatus == 0) {

			this.router.navigateByUrl('/' + ROUTS.MAINTENANCE);

		} else if (errorStatus == 403) {

			this.router.navigateByUrl('/' + ROUTS.NOT_AUTHORIZED);

		} else if (errorStatus == 404) {

			this.router.navigateByUrl('/' + ROUTS.ERROR);

		} else if (errorStatus == 401) {

			this.removeData();
			this.sessionExpiredMessage = "Your session is expired. Please login again.";
			this.router.navigateByUrl('/' + ROUTS.SIGN_IN);

		} else {

			this.router.navigateByUrl('/' + ROUTS.ERROR);
		}

	}


	/**
	 * Get access token from cookie
	 */
	getToken(): string {
		return this.decrypt(Cookie.get(CONSTANTS.COOKIE_ACCESSTOKEN));
	}

	getRefreshToken(): string {
		return this.decrypt(Cookie.get(CONSTANTS.COOKIE_REFRESHTOKEN));
	}

	/**
	 * Get common request header with authorization token for content type 'URLEncoded'
	 */
	getCommonRequestOptionsForURLEncoded(authorization: string): RequestOptions {

		let headers = new Headers();
		headers.append('Authorization', (authorization == URL.AUTHORIZATION ? authorization : authorization + this.getToken()));
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		this.setheadersforFormatAndLanguage(headers);
		return new RequestOptions({ headers: headers });
	}

	/**
	* Get common request header with authorization token for content type 'URLEncoded'
	*/
	getCommonRequestOptionsWithoutContentType(authorization: string): RequestOptions {

		let headers = new Headers();
		headers.append('Authorization', (authorization == URL.AUTHORIZATION ? authorization : authorization + this.getToken()));
		this.setheadersforFormatAndLanguage(headers);
		return new RequestOptions({ headers: headers });
	}

	/**
	 * Get common request header with authorization token for content type 'URLEncoded'
	 */
	getCommonRequestOptionsForRefreshToken(authorization: string): RequestOptions {

		let headers = new Headers();
		headers.append('Authorization', (authorization == URL.AUTHORIZATION ? authorization : authorization + this.getRefreshToken()));
		headers.append('Content-Type', 'application/x-www-form-urlencoded');
		this.setheadersforFormatAndLanguage(headers);
		return new RequestOptions({ headers: headers });
	}

	/**
	 * Get common request header with set ResponseContentType as Blob
	 * 
	 */
	getCommonRequestOptionsWithoutContentTypeWithResponseTypeBlob(authorization: string): RequestOptions {

		let headers = new Headers();
		headers.append('Authorization', (authorization == URL.AUTHORIZATION ? authorization : authorization + this.getToken()));
		headers.append('Accept', 'application/pdf');
		this.setheadersforFormatAndLanguage(headers);
		return new RequestOptions({ headers: headers, responseType: ResponseContentType.Blob });
	}

	/**
	 * Set header for date/time/datetime/duration format and language
	 */
	setheadersforFormatAndLanguage(headers: any) {

		headers.append('Date-Format', this.getDateFormat());
		headers.append('Time-Format', this.getTimeFormat());
		headers.append('Time-Zone', this.getLocalTimeZone());

		return headers;

	}

	/**
	 * Get date format
	 */
	getDateFormat() {

		if (!localStorage.hasOwnProperty(CONSTANTS.DATE_FORMAT))
			localStorage.setItem(CONSTANTS.DATE_FORMAT, CONSTANTS.DATE_FORMAT_DEFAULT);

		return localStorage.getItem(CONSTANTS.DATE_FORMAT);

	}

	/**
	 * Get time format
	 */
	getTimeFormat() {

		if (!localStorage.hasOwnProperty(CONSTANTS.TIME_FORMAT))
			localStorage.setItem(CONSTANTS.TIME_FORMAT, CONSTANTS.TIME_FORMAT_DEFAULT);

		return localStorage.getItem(CONSTANTS.TIME_FORMAT);

	}

	/**
	 * Get date time format
	 */
	getDateTimeFormat() {

		if (!localStorage.hasOwnProperty(CONSTANTS.DATE_TIME_FORMAT))
			localStorage.setItem(CONSTANTS.DATE_TIME_FORMAT, CONSTANTS.DATE_TIME_FORMAT_DEFAULT);

		return localStorage.getItem(CONSTANTS.DATE_TIME_FORMAT);

	}

	/**
	 * Get Local Browser Timezone id
	 */
	getLocalTimeZone() {

		if (!localStorage.hasOwnProperty(CONSTANTS.TIME_ZONE)) {

			moment.tz.setDefault(moment.tz.guess());
			localStorage.setItem(CONSTANTS.TIME_ZONE, moment.tz.guess());
		}

		return localStorage.getItem(CONSTANTS.TIME_ZONE);

	}

	redirectToLogin() {

		this.router.navigate([ROUTS.SIGN_IN]);
	}

	getWordFromNumber(value) {

		if (value === 0) {
			return 'Zero';
		}

		const amount = Math.floor(value);
		const paisa = Math.round((value - amount) * 100);

		let words = this.convertToWords(amount);

		if (paisa > 0) {
			words += ' Rupees and ' + this.convertToWords(paisa) + ' Paisa';
		} else {
			words += ' Rupees';
		}

		return words.trim();
	}

	private convertToWords(num: number): string {
		let words = '';
		const crore = Math.floor(num / 10000000);
		const lakh = Math.floor((num % 10000000) / 100000);
		const thousand = Math.floor((num % 100000) / 1000);
		const hundred = Math.floor((num % 1000) / 100);
		const ten = Math.floor(num % 100);

		if (crore > 0) {
			words += this.ones[crore] + ' Crore ';
		}

		if (lakh > 0) {
			words += this.convertTens(lakh) + ' Lakh ';
		}

		if (thousand > 0) {
			words += this.convertTens(thousand) + ' Thousand ';
		}

		if (hundred > 0) {
			words += this.ones[hundred] + ' Hundred ';
		}

		if (ten > 0) {
			words += this.convertTens(ten);
		}

		return words.trim();
	}

	private convertTens(num: number): string {
		let words = '';
		if (num >= 20) {
			const tensDigit = Math.floor(num / 10);
			const onesDigit = num % 10;
			words += this.tens[tensDigit] + ' ' + this.ones[onesDigit] + ' ';
		} else if (num >= 11) {
			words += this.teens[num - 10] + ' ';
		} else {
			words += this.ones[num] + ' ';
		}
		return words;
	}

}
